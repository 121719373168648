<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
     <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>공지사항</h2>
                    <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a href="#">SUPPORT</a></li>
                        <li><a href="#">공지사항</a></li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="sub-section notice">
            <div class="innWrap">
                <div class="board-view">
                    <div class="viewTit">{{detail.notice_title}}</div>
                    <div class="viewCon" v-html="detail.notice_detail_info"></div>
                    <div class="viewPrev" v-if="pre" @click="goDetail(pre.seq)">
                        <span>이전글</span>
                        {{pre.notice_title}}
                    </div>
                    <div class="viewPrev" v-else>
                        <span>이전글</span>
                        이전글 없습니다.
                    </div>
                    <div class="viewNext" v-if="next" @click="goDetail(next.seq)">
                        <span>다음글</span>
                        {{next.notice_title}}
                    </div>
                    <div class="viewNext" v-else>
                        <span>다음글</span>
                        다음글 없습니다.
                    </div>
                    <div class="btnBottomArea">
                        <a @click="$router.push('/goSupNot000')" class="btn-lg fix btn-line03">목록</a>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
      response: ''
      , detail: {}
      , pre: {}
      , next: {}
    }
  },
  created: function () {

  },
  mounted: async function () {
    if(this.$route.params.seq != undefined){
      await this.getNoticeDetail(this.$route.params.seq)
    }else{
      this.$router.push('/goSupNot000')
    }
  },
  methods: {
    async getNoticeDetail(seq) {
      let payload = {
        seq: seq
      }
      await this.$store.dispatch('gigaoffice/getNoticeInfoDetail', payload).then(res => {
				this.response = res.data.response
        this.detail = this.response.detail
        this.pre = this.response.pre
        this.next = this.response.next
			})
			.catch(err => {
				console.log(err)
			})
    },
    goDetail (seq) {
      this.getNoticeDetail(seq)
    }
  }
}
</script>